import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { graphql, Link } from 'gatsby';
import { MathJax } from 'better-react-mathjax';
export const pageQuery = graphql`
  query {
    allMdx(
      filter: {frontmatter: {type: {eq: "subcategory"}}, fileAbsolutePath: {regex: "/softwares/"}}
    )
    
    {
      edges {
        node {
          frontmatter {
            type
            title
          }
          slug
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Softwares`}</h1>
    <p>{`Download 100 % working lifetime softwares`}</p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1BIxG03LyuE5i0ovzNSSjfJMN4qPqDUbM"
      }}>{`EPANET`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1OL5onf27LyXpogtxtflyUO9NsCWqCMF5"
      }}>{`ETABS 9.7.1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=16elCnzqFzbDP4qq8Qv_c2bqKOWftqsJz"
      }}>{`Primavera P6 Pro R18.8 2018 Version`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=11DeW_LUBM6cNbkJ6cr3FRLVSQeGHmkym"
      }}>{`SAP 2000 Ver. 10.0.1`}</a></p>
    <div>
  {props.data.allMdx.edges.map(x => <Link to={`/${x.node.slug}`} key={x.node.frontmatter.title} mdxType="Link">
              <div>
                {x.node.frontmatter.title}
              </div>
            </Link>)}
          
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      